import router from '@/router/Router';

const irPara = (rota, query) => {
    query
        ? router.push({ name: rota, params: { id: query } })
        : router.push({ name: rota });
};

const retornar = () => {
    router.go(-1);
};

export default {
    irPara,
    retornar,
};
