<template>
    <nav>
        <cabecalho title="MENU.INICIO" />
        <div class="mb-5">
            <h5 class="font">
                {{ $t('GERAL.HOME_BEM_VINDO') }}
            </h5>
            <p>
                {{ $t('GERAL.HOME_DESCRICAO') }}
            </p>
            <span>
                {{ $t('GERAL.HOME_APROVEITE') }}
            </span>
        </div>
        <b-row class="mb-2">
            <b-col class="mb-3" v-for="menu in menus" :key="menu.id">
                <b-button
                    variant="teste"
                    class="botao-home"
                    :pill="menuAtivo.id === menu.id"
                    @click="ativarMenu(menu)"
                >
                    <div>
                        <feather
                            class="icone-mennu-home"
                            :type="menu.icon"
                        ></feather>
                    </div>
                    <span>
                        {{ $t(menu.i18n) }}
                    </span>
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="auto" v-for="link in menuAtivo?.children" :key="link.id">
                <router-link class="link-home w-100" :to="`${link.to}`">
                    {{ $t(link.i18n) }}
                </router-link>
            </b-col>
        </b-row>
    </nav>
</template>
<script>
// Utils & Aux:
import breadcrumb from '@/common/utils/breadcrumb';
import listaMenus from '@/layouts/full-layout/ListaMenus.js';
// Components:
import {
    Cabecalho,
    //  Titulo
} from '@/components/headers/index';

export default {
    components: {
        Cabecalho,
        // Titulo,
    },
    data() {
        return {
            menus: [],
            menuAtivo: {},
        };
    },
    mounted() {
        breadcrumb.definir(this.$store, [{ titulo: this.$t('GERAL.HOME') }]);
        this.menus = listaMenus.filter((m) => m.id !== 'Home');
    },
    watch: {
        menus: {
            handler(value) {
                this.menuAtivo = value[0];
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        ativarMenu(menu) {
            this.menuAtivo = menu;
        },
    },
};
</script>

<style>
.icone-mennu-home {
    height: 25px;
    width: 25px;
    margin: auto;
}

.btn:not(.b-calendar .btn).botao-home {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    padding: 16px 24px !important;
    gap: 10px !important;
}

.btn:not(.b-calendar .btn).botao-home:hover {
    border-color: transparent;
    background: #fff;
    filter: brightness(0.98);
    color: #344054;
}

.botao-home {
    display: grid;
    transition: filter 0.02s;
    background: #fff;
    box-shadow: 2px 6px 24px 0px rgba(16, 24, 40, 0.05);
    border-color: transparent;
    color: #344054;
    font-weight: 500;
}

.botao-home.rounded-pill {
    background: #eff8ff;
    transition: filter 0.02s;
    box-shadow: 2px 6px 24px 0px rgba(16, 24, 40, 0.05);
    color: #1570ef;
}

.botao-home.rounded-pill:hover {
    background: #eff8ff !important;
    color: #1570ef;
}

.link-home {
    display: block;
    border-radius: 8px;
    background: #fff;
    box-shadow: 2px 6px 24px 0px rgba(16, 24, 40, 0.05);
    padding: 10px 24px !important;
    color: #344054;
    margin-bottom: 10px;
}
</style>
